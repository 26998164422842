.App {
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  
  }

  .form-control-number-input {
    min-width: 0;
    margin-left: 5%;
    margin-right: 1%;
    width: auto;
  }

  .form-control-text-input {
    min-width: 0;
    margin-left: 1%;
    margin-right: 1%;
    height: 85vh;
    overflow-y: auto;
    padding-bottom: 0%;
  }

  .get-otp {
    min-width: 0;
    margin-left: 5%;
    margin-right: 1%;
    width: auto;
  }
  .button-group-1{
    display: flex;
    justify-content: space-between;
    max-width:auto;
    padding-top: 0.5%;
  }
  .button1{
    margin-left: 1%;
    position: absolute;
  }
  .button2{
    right: 1%;
    position: absolute;
  } 

  .checkmark {
    -ms-transform: translate(-10%, -10%);
    transform: translate(-10%, -10%);
    content-visibility: hidden;
    display: inline-block;
    transform: rotate(45deg);
    height: 35px;
    width: 20px;
    border-bottom: 7px solid #78b13f;
    border-right: 7px solid #78b13f;
  }  

  .nothing{
    padding: auto;
  }

  .load {
      content-visibility: hidden;
      border-radius: 50%;
      border: 3px solid #fff;
      width: 30px;
      height: 30px;
      border-left: 3px solid transparent;
      border-bottom: 3px solid transparent;
      animation: loading1 1s ease infinite;
      z-index: 10
    }
  .load1 {
    position: relative;
    top: 50vh;
    left: 50ch;
    padding: auto;
    content-visibility: hidden;
    border-radius: 50%;
    border: 3px solid black;
    width: 30px;
    height: 30px;
    border-left: 3px solid transparent;
    border-bottom: 3px solid transparent;
    animation: loading1 1s ease infinite;
    z-index: 10
  }

    @keyframes loading1 {
        0% {
            transform: rotate(0deg)
        }

        100% {
            transform: rotate(360deg)
        }
    }

  .App-header {
    background-color: white;
    flex-direction: column-reverse;
    /* justify-content: center;*/ 
    font-size: calc(10px + 2vmin);
    color: #282c34;
  }
  
  .App-link {
    color: #61dafb;
  }
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }